import { MarketsInfoData } from 'domain/synthetics/markets'
import { KeyedMutator, mutate } from 'swr'
import { create } from 'zustand'

interface MarketsInfoDataStoreType {
  marketsInfoData: MarketsInfoData
  mutate: KeyedMutator<MarketsInfoData>
}

interface PoolStoreState {
  tokenA: string | null
  tokenB: string | null
  poolName: string
  firstTokenInputValue: string
  secondTokenInputValue: string
  focusedInput: 'longCollateral' | 'shortCollateral' | 'market'
  marketsInfoDataStore: MarketsInfoDataStoreType
  marketDetailAddress: string
  collapsedState: boolean
  marketsInfoData: MarketsInfoData
}

interface PoolStoreActions {
  setTokenA: (tokenA: string) => void
  setTokenB: (tokenB: string) => void
  setPoolName: (poolName: string) => void
  resetStore: () => void
  setSecondTokenInputValue: (secondTokenInputValue: string) => void
  setFirstTokenInputValue: (firstTokenInputValue: string) => void
  setFocusedInput: (
    focusedInput: 'longCollateral' | 'shortCollateral' | 'market',
  ) => void
  setMarketsInfoDataStore: (
    marketsInfoDataStore: MarketsInfoDataStoreType,
  ) => void
  setMarketDetailAddress: (marketDetailAddress: string) => void
  setCollapsedState: (collapsedState: boolean) => void
  setMarketsInfoData: (marketsInfoData: MarketsInfoData) => void
}

const initialState: PoolStoreState = {
  tokenA: null,
  tokenB: '0x3355df6D4c9C3035724Fd0e3914dE96A5a83aaf4',
  poolName: '',
  firstTokenInputValue: '',
  secondTokenInputValue: '',
  focusedInput: 'longCollateral',
  marketsInfoDataStore: {
    marketsInfoData: {},
    mutate,
  },
  marketDetailAddress: '',
  collapsedState: true,
  marketsInfoData: {},
}

export const useCreatePoolStore = create<PoolStoreState & PoolStoreActions>(
  (set) => ({
    ...initialState,
    setTokenA: (tokenA: string) => set({ tokenA }),
    setTokenB: (tokenB: string) => set({ tokenB }),
    setPoolName: (poolName: string) => set({ poolName }),
    resetStore: () => set(initialState),
    setSecondTokenInputValue: (secondTokenInputValue: string) =>
      set({ secondTokenInputValue }),
    setFirstTokenInputValue: (firstTokenInputValue: string) =>
      set({ firstTokenInputValue }),
    setFocusedInput: (
      focusedInput: 'longCollateral' | 'shortCollateral' | 'market',
    ) => set({ focusedInput }),
    setMarketsInfoDataStore: (marketsInfoDataStore: MarketsInfoDataStoreType) =>
      set({ marketsInfoDataStore }),
    setMarketDetailAddress: (marketDetailAddress: string) =>
      set({ marketDetailAddress }),
    setCollapsedState: (collapsedState: boolean) => set({ collapsedState }),
    setMarketsInfoData: (marketsInfoData: MarketsInfoData) =>
      set({ marketsInfoData }),
  }),
)
