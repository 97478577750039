import { ReactNode } from 'react'

interface Props {
  label: string
  value: ReactNode
  subValue?: ReactNode
  className?: string
}

const CardRow = ({ label, value, subValue, className }: Props) => {
  return (
    <div className="flex w-full items-center justify-between">
      <div className="flex flex-1 items-center justify-between">
        <p className="text-sm font-normal leading-4 text-th-fgd-2">{label}</p>
        <div className="text-sm font-medium leading-4 text-th-fgd-1">
          {value}
        </div>
      </div>
      <div className={`flex w-36 justify-end ${className}`}>
        <p className="max-w-fit text-sm font-medium leading-4 text-th-fgd-3">
          {subValue}
        </p>
      </div>
    </div>
  )
}
export default CardRow
